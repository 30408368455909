import React, {useEffect, useState} from 'react';
import {Alerts, SessionTimeout} from './components/notify'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import axios from 'axios'
import Cookies from 'js-cookie'
import {config,  ServerUrl} from './components/include'
import {encrypt, decrypt} from './components/authentication'


import dashboard from './account/dashboard';
import createAccount from './account/create_account';
import edit_account from './account/edit_account';
import customers from './account/customers';
import transaction_history from './account/transaction_history';
import customer_transaction from './account/customer_transaction';
import customers_pin from './account/customers_pin';
import error404 from './account/error404';
import settings from './account/settings';
import users from './account/users';

const ProtectedView =()=>{

const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();
var code =decrypt(Cookies.get('hsbcspringeqrbcd'))
const jwt = decrypt(Cookies.get('hsbcspringeqrbtk'))

fd.append('code', code)
fd.append('jwt', jwt)


    let url = ServerUrl+'/login_refresh_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
			var inOneMinutes = Date.now() + 58 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
			  
			  localStorage.setItem('_eqtmrbk', inSixtyMinutes)
				localStorage.setItem('_eqbtmrbk', inOneMinutes)
				

			Cookies.set('hsbcspringeqrbtk', encrypt(response.data.jwt),  {expires: inOneHours }) 
			Cookies.set('hsbcspringeqrbcd', encrypt(response.data.code),  {expires: inOneHours })
      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
    window.location.href='/'
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(localStorage.getItem('_eqbtmrbk'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     

useEffect(()=>{
   
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(localStorage.getItem('_eqtmrbk'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000) 


}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Router  >
      <Switch>

      
    <Route exact path="/dashboard" component={dashboard} />
    <Route exact path="/customer/create" component={createAccount} />
    <Route exact path="/customer/edit/:code" component={edit_account} />
    <Route exact path="/transaction_history/:code" component={transaction_history} />
    <Route exact path="/customers" component={customers} />
    <Route exact path="/customers/transactions" component={customer_transaction} />
    <Route exact path="/users" component={users} />
    <Route exact path="/settings" component={settings} />
    <Route exact path="/customers/pins" component={customers_pin} />

    <Route exact  component={error404} />
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
