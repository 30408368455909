import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning, WarningModal} from '../components/notify'
import {ServerUrl,  config,  PictureUrl, Currency} from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { formatGroupLabel, FormatNumber, longDate } from '../components/globalFunction';
import { useParams } from 'react-router';

const TransactionHistory =()=> {

	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
let params = useParams()

    const [errors, setErrors] = useState({});
    const [content, setContent] = useState([])
    const [history, setHistory] = useState([])

    let Initials ={
	
		isTransaction:false,
		transactionType:{value: "Deposit", label: "Deposit", transactionType: "Credit"},
		typeLabel:'Deposit',
		typeValue:'Credit',
		accountNumber:'',
        description:'',
		customerCode:params.code,
		amount:0,
		balance:0
	}
    const [transaction, setTransaction] = useState(Initials);

    

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       
        var sql ="Select * from tbl_customers where code = '"+params.code+"' limit 1 " ;
          
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            if(result.data.length!==0){
            setContent(result.data[0])
            }else{
                setContent([]) 
            }
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }

  const fetchHistory =()=>{
	
    var sql ="Select * from tbl_transaction where customerCode ='"+params.code+"' order by ID ASC";
    
    const fd = new FormData();
    fd.append("sql", sql);
    //fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller.php';
    
    axios.post(url, fd, config).then(result=>{ 
        setHistory(result.data)
    })
    .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})
    })

}

const handleSelect = (option, action)=>{
    setTransaction({...transaction, [action.name]: option, typeValue:option.transactionType, typeLabel:option.label, amount:0});
     }


     const handleChangeDescription = (event)=>{
         let {name, value} = event.target
        setTransaction({...transaction, [name]: value});
         }

const handleTransactionAmount =(event)=>{
    const {name, value} = event.target;
    var balance = content.accountBalance
    if(transaction.typeValue ==='Credit'){
        balance = Number(balance) + Number(value)
    }else if(transaction.typeValue==='Debit'){
        balance = Number(balance) - Number(value)
    }
    
    setTransaction({...transaction, [name]: value, balance:balance, accountNumber:content.accountNumber});
    setErrors({...errors, [name]:'', errorMessage:'' })

}


const handleDeleteTransaction =(ID, transactionMode, amount)=>{
    let close =   document.getElementById('btnWarningDialog-'+ID)
    close.click();
     
var balance = content.accountBalance
    if(transactionMode==='Credit'){
        balance = Number(balance) - Number(amount)
    }else if(transactionMode==='Debit'){
        balance = Number(balance) + Number(amount)
    }

    
    setNotice({...notice, 
        isLoading: true}) 

    const fd = new FormData();
    fd.append('ID', ID)
    fd.append('accountNumber', content.accountNumber)
    fd.append('balance', balance)
   // fd.append("jwt", Token);
let url = ServerUrl+'/update_controller.php?tablename=tbl_update_transaction';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
          Alerts('Saved!', 'success', response.data.message)
              } else{
                setErrors({...errors, errorMessage: response.data})
              }   
      })
      .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})
      }).finally(()=>{
          setNotice({...notice, 
              isLoading: false
          })
          fetchContent()
         fetchHistory()
      }) 	
}


  
  const handleDeleteAccount =(ID)=>{

    let close =   document.getElementById('btnWarningDialog-delAccount')
    close.click();
    
    setNotice({...notice, 
        isLoading: true}) 

    const fd = new FormData();
    fd.append('ID', 'code')
    fd.append('data', params.code)
    //fd.append('jwt', Token)
let url = ServerUrl+'/delete_controller.php?tablename=tbl_customers';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
          Alerts('Saved!', 'success', response.data.message)
          window.open('/customers', '_self')
              } else{
                setErrors({...errors, errorMessage: response.data})
              }   
      })
      .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})
      }).finally(()=>{
          fetchContent()
          setNotice({...notice, 
              isLoading: false
          })
      }) 
    
    
}

 
const handleSaveTransaction =()=>{
    if(transaction.amount===0){
      setErrors({...errors, errorMessage: 'Transaction amount must be greater than 0'})

      window.scrollTo({top:0, left:0, behavior:'smooth'})

    }else  if(transaction.balance < 0){
        setErrors({...errors, errorMessage: 'Transaction not allow'})
  
        window.scrollTo({top:0, left:0, behavior:'smooth'})
  
      }  else{
  setNotice({...notice, 
      isLoading: true})     
        
      
      let formData = transaction;
      const fd = new FormData();
      for(let k in formData){
        fd.append(k, formData[k])
      }
      fd.append("email", content.email);
         let url = ServerUrl+'/save_controller.php?tablename=tbl_save_transaction';
        axios.post(url, fd, config)
        .then(response =>{
         if(response.data.type ==='success'){
            
            Alerts('Saved!', 'success', response.data.message)
            
                } else{
            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            window.scrollTo({top:0, left:0, behavior:'smooth'})
                }   
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
            window.scrollTo({top:0, left:0, behavior:'smooth'})
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })
            setTransaction(Initials)
           fetchContent()
           fetchHistory()
        }) 
      }
}



const tableHeader = [
    


{dataField: 'dateCreate',  text: 'Date',  sort: true, editor: {
    type: Type.DATE},  headerStyle: {backgroundColor: '#f0ff4d'}},
{dataField: 'transactionType',  text: 'Type',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}},


{dataField: 'description',  text: 'Description',  sort: true,  headerStyle: {backgroundColor: '#f0ff4d'}},

{dataField: 'debit',  text: 'Debit ',  sort: true, editable: false, formatter:(cell, row)=>row.transactionMode==='Debit'?FormatNumber(row.amount):'', headerStyle: {backgroundColor: '#f0ff4d'}},

{dataField: 'credit',  text: 'Credit ',  sort: true, editable: false, formatter:(cell, row)=>row.transactionMode==='Credit'?FormatNumber(row.amount):'', headerStyle: {backgroundColor: '#f0ff4d'}},

{dataField: 'balance',  text: 'Balance ',  sort: true, editable: false, formatter:(cell)=>FormatNumber(cell), headerStyle: {backgroundColor: '#f0ff4d'}},

{dataField: 'ID',  text: 'Action',  sort: true, editable: false, headerStyle: {backgroundColor: '#f0ff4d'}, formatter:(cell, row)=><div>
	
<WarningModal  handleAction={(e)=>handleDeleteTransaction(row.ID)} mID={row.ID} />

<button type="button" className="btn btn-warning btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`} title="Delete"><i className="icon-trash-alt"></i> Delete</button></div>},
 ];

 


									
 const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_transaction'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             
                             
                                            </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  


  const handleUpdateAccount=(newValue, column)=>{  
	setNotice({...notice, 
		isLoading: true
	})     
	const fd = new FormData(); 
		fd.append('newValue', newValue);
		fd.append('column', column);
		fd.append('ID', content.ID);
		//fd.append("jwt", Token);
	   let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_customers'
	  axios.post(url, fd, config)
	  //.then(result => console.log(result.data))
	  .then()
	  .catch((error)=>Alerts('Error!', 'danger', error.message))
	  .finally(()=>{
		setNotice({...notice, 
			isLoading: false
		})
	   fetchContent()
	})  
	} 


 useEffect(()=>{
    fetchContent()
    fetchHistory()
   },[]);

    return (<Template 
    name='Transaction History' 
    title='All credit and debit transaction made' 
    menu='Customers'
    menuLink='/customers'
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}

{content.length!==0?
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Customer Details</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                    <div className="row">
                    <div className="col-sm-5">
                        <div className="user-block">
                     <img width="100" height="100" className="rounded-circle" title={content.gender} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/customers/Male.png"}} src={PictureUrl+"/customers/"+content.passport} alt="User " />
                            <p className="text-bold"> {content.fullname} </p>

                            <span className="text-blue" style={{fontSize:'13px'}}><b>{content.accountNumber}</b>&nbsp;
</span>

                            <span >
							<br />{content.accountStatus ==='Active'?
							
<span class="badge bg-green-400 align-self-center ml-auto"> <i  className="icon-checkmark fa-2x"></i> {content.accountStatus} </span>:
							
<span class="badge bg-danger-400 align-self-center ml-auto"> <i  className="icon-cross2 fa-2x"></i> {content.accountStatus} </span>}
							
							<br />	<br />
								
								Available Balance: { FormatNumber(Number(content.accountBalance))}<br />
								
								Ledger Balance: {FormatNumber(content.accountBalance)}<br />
								</span>
							
                                
								
                        </div>       
                    </div>

                    <div className="col-sm-3">
                        <ul className="list-unstyled">
                        
                        </ul>
                    </div>
                    <div className="col-sm-4">
                        <ul className="list-unstyled">
                        
                        <li><b>Account Type:</b> {content.accountType}  </li>
<br/>
                        <li><b>Mobile:</b> <a target="_blank" rel="noopener noreferrer" href={`tel:${content.telephone}`}> {content.telephone} </a>          
						 <div className="btn-group-horizontal">
                
<button type="button"  className="btn btn-primary btn-sm"><i className="fa fa-commenting-o"></i> Send SMS</button>
            </div></li><br/>
                         <li><b>Email:</b><a target="_blank" rel="noopener noreferrer" href={`mailto:${content.email}`}> {content.email}</a>
            <div className="btn-group-horizontal">
				
<button type="button"  className="btn btn-primary btn-sm"><i className="fa fa-envelope-o"></i> Send Email</button>
                
            </div></li> 
<br/>
            <span style={{fontSize:'13px', color:'#000000'}}>Last Transaction: {content.lastUsed} <br/> Account Created On: {longDate(content.dateCreate)} </span>
                        </ul>
                    </div>

                </div>
              

                <WarningModal  handleAction={()=>handleDeleteAccount(content.ID)} mID={'delAccount'} /> 

                <div className="row">
                    <div className="col-sm-8"><br/>
            <div className="btn-group-horizontal">
			{content.accountStatus ==='Active'? <button type="button" className="btn btn-danger" onClick={()=>handleUpdateAccount('Inactive', 'accountStatus')}>  Disable Account</button>:
			<button type="button" className="btn btn-primary" onClick={()=>handleUpdateAccount('Active', 'accountStatus')} > Enable Account</button>}&nbsp;&nbsp;

                <button type="button"  className="btn btn-success" onClick={()=>setTransaction({...transaction, isTransaction:true})}> Add Transaction</button>&nbsp;&nbsp;
				<a href="/customers/pins" className="btn btn-indigo bg-indigo-400 text-indigo-400 border-indigo-400" >PINS</a>
            </div>
                    </div>
                    <div className="col-sm-4"><br/>
                        <div className="pull-left">

						<button type="button" className="btn btn-outline bg-pink-400 text-pink-400 border-pink-400" data-toggle="modal"   data-target={`#warningdialog-${'delAccount'}`} > <i className="icon-trash"></i>Delete Account</button> &nbsp;&nbsp;
                        
                        <a href={`/customer/edit/${content.code}`} className="btn btn-outline-warning" ><i className="icon-pen"></i> Edit Customer</a>
					
						
                        </div>
                    </div>
                </div>
              


					</div>
				</div>
:""}

{transaction.isTransaction?     <div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Add Transaction</h5>
					
					</div>

					<div className="card-body">
						

                    <div className="row">
								<div className="col-md-9">
									<fieldset>
										

        <div className="form-group">
            <label>Selection transaction type:</label>
            <Select options={
						[{value:'Credit', label:'Credit', options:[
							{value:'Deposit', label:'Deposit', transactionType:'Credit'},
							{value:'Interest', label:'Interest', transactionType:'Credit'},
							{value:'Dividend', label:'Dividend', transactionType:'Credit'},
							{value:'Transfer In', label:'Transfer In', transactionType:'Credit'},
							{value:'Bonus', label:'Bonus', transactionType:'Credit'},
							{value:'Debit Reverse', label:'Debit Reverse', transactionType:'Credit'}
						]},
						
						{value:'Debit', label:'Debit', options:[
							{value:'Withdrawal', label:'Withdrawal', transactionType:'Debit'},
							{value:'Bank Fees', label:'Bank Fees', transactionType:'Debit'},
							{value:'Transfer Out', label:'Transfer Out', transactionType:'Debit'},
							{value:'Commission', label:'Commission', transactionType:'Debit'},
							{value:'Purchase', label:'Purchase', transactionType:'Debit'}
						]}]
				}
				formatGroupLabel={formatGroupLabel} 
                
onChange={handleSelect}  name="transactionType"   value={transaction.transactionType} styles={selectStyles} />

        </div>
        <div className="form-group">
            <label>Description:</label>
            <textarea type="text" row="4" name="description" value={transaction.description} placeholder="Description" onChange={handleChangeDescription} className='form-control' >
            {transaction.description} 

                </textarea> 

        </div>

        <div className="form-group">
            <label>Amount:</label>
            <input type="number"  name="amount" value={transaction.amount} placeholder="amount" onChange={handleTransactionAmount} className='form-control' /> 

        </div>


        </fieldset>
                </div>


                </div>

                <div className="row"> 
            
            <div className="col-md-12 text-center">
            <div className="input-group">
						
                        <button type="button" className="btn btn-primary btn-sm" onClick={handleSaveTransaction} > <i className="icon-paperplane ml-2"></i> Save </button>
                        &nbsp;
                        <button type="button" className="btn btn-warning btn-sm"   onClick={()=>setTransaction({...transaction, isTransaction:false})}>Close</button>
                        </div>
    </div>
            
            </div>



					</div>
				</div>:''}
                                
                {content.length!==0?<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">Transaction History</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={history}  columns={tableHeader}  />}
            </div>
					</div>
				</div>:''}




</Template>
);
       
}

export default React.memo(TransactionHistory)  
