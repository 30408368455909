import React, {useState, useEffect} from 'react'
const Breadcrumb = (props)=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    


    return <div className="mb-3 border-top-1 border-top-primary">
					<div className="page-header page-header-light" >
						<div className="page-header-content header-elements-md-inline">
							<div className="page-title">
								<h5>
									<i className="icon-arrow-left52 mr-2"></i> <span className="font-weight-semibold">{props.name}</span>
									<small className="d-block text-muted">{props.title}</small>
								</h5>
							</div>

							<div className="header-elements py-0">
								<div className="breadcrumb">
									<a href="/dashboard" className="breadcrumb-item"><i className="icon-home2 mr-2"></i> Home</a>
									{props.menu?	<a href={props.menuLink} className="breadcrumb-item">{props.menu}</a>:''}
									<span className="breadcrumb-item active">{props.name}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

}
export default React.memo(Breadcrumb)