import React, {useState, useEffect} from 'react'
import { authenticate } from './authentication';

import Breadcrumb from './breadcrumb';
import Footer from './footer'
import Sidebar from './sidebar';
import Topbar from './topbar';

const Template = (props) =>{
    
   useEffect(()=>{
       authenticate()
   })
    return (<>
<Topbar />

<div className="page-content">

    
   <Sidebar {...props} />
   <a href="#!" data-toggle="modal" id='openlockscreenModal' data-target='#lockscreen' ></a>


    <div className="content-wrapper">

       
        
       <Breadcrumb {...props} />
       
        <div className="content">

          
         
          {props.children}
           

        </div>


     <Footer />

    </div>
    
</div>
        
    </>)
}
export default React.memo(Template)