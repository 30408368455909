import React, {useState, useEffect} from 'react'
import Template from '../components/template';

const Error404 =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});


    return (<Template>
<div className="content d-flex justify-content-center align-items-center">

<div className="flex-fill">

    <div className="text-center mb-3">
        <h1 className="error-title">404</h1>
        <h5>Oops, an error has occurred. Page not found!</h5>
    </div>
   
    <div className="row">
        <div className="col-xl-4 offset-xl-4 col-md-8 offset-md-2">

            <form action="#">
                <div className="input-group mb-3">
                    <input type="text" className="form-control form-control-lg" placeholder="Search" />

                    <div className="input-group-append">
                        <button type="submit" className="btn bg-slate-600 btn-icon btn-lg"><i className="icon-search4"></i></button>
                    </div>
                </div>
            </form>
           
            <div className="row">
                <div className="col-sm-6">
                    <a href="#" className="btn btn-primary btn-block"><i className="icon-home4 mr-2"></i> Dashboard</a>
                </div>

                <div className="col-sm-6">
                    <a href="#" className="btn btn-light btn-block mt-3 mt-sm-0"><i className="icon-menu7 mr-2"></i> Advanced search</a>
                </div>
            </div>
           
        </div>
    </div>
    
</div>

</div>

</Template>
);
       
}

export default React.memo(Error404)  
