import React from 'react'

export const LoadEffect = (props)=>{
    return <div className={props.className}>
          <div className="loader animation-start ">
    <span className="circle delay-1 size-2"></span>
    <span className="circle delay-2 size-4"></span>
    <span className="circle delay-3 size-6"></span>
    <span className="circle delay-4 size-7"></span>
    <span className="circle delay-5 size-7"></span>
    <span className="circle delay-6 size-6"></span>
    <span className="circle delay-7 size-4"></span>
    <span className="circle delay-8 size-2"></span>
</div></div>
};

export const Loader = ()=>{
    return  <div className='loading'>

        </div>
}

 export const TableLoader = ()=>{
    return <div id="loading">
            <div className="loader"></div>
        </div>
}

export default React.memo(Loader)