import React from 'react';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import login from './main/login';
import ProtectedView from './prottected_view';

function App() {
  
  return (<>

    <ReactNotification />
   <Router  >
    <Switch>

    <Route exact path="/" component={login} />
    <ProtectedView />
    
      </Switch>
      </Router>
      </>
  );
}

export default App;
