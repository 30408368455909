import React, {useState, useEffect} from 'react'
import Template from '../components/template';
import {Alerts, LabelWarning} from '../components/notify'
import {ServerUrl,  config, Usertype, staffCode} from '../components/include'
import axios from 'axios'
import { Loader, TableLoader } from '../components/loader';


import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const CustomersPin =()=> {

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [errors, setErrors] = useState({});
         
    const [content, setContent] = useState([])

    const fetchContent =()=>{
        setNotice({...notice, 
            isDataFetching: true
        });
       

        var sql ="Select p.code, p.ID, p.accountNumber,  p.taxPin, p.authorCode, p.taCode, p.InsuranceCode, p.frCode, p.dateCreate, c.fullname  from tbl_pin p, tbl_customers c where c.created_by ='"+staffCode+"' and c.code = p.customerCode order by p.ID DESC " ;

        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setContent(result.data)

            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }
  

  

const tableHeader = [

    {dataField: 'fullname', text: 'Customer', editable:false, formatter:(cell, row)=><span>{cell}<br/><b>{row.accountNumber}</b></span>},
{dataField: 'authorCode', text: 'Authorization Code'},
{dataField: 'taxPin', text: 'Tax Pin'},
{dataField: 'taCode', text: 'Transfer Access Code'},

{dataField: 'InsuranceCode', text: 'Insurance Code'},

{dataField: 'frCode', text: 'Federal Regulation Code'},

 {dataField: 'dateCreate', text: 'Date Added', editable: false},

 ];

 

									
 const TableRecord=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
            fd.append('newValue', newValue);
            fd.append('column', column.dataField);
            fd.append('ID', ID);
           // fd.append("jwt", Token);
           let url = ServerUrl+'/updateCustom_controller.php?tablename=tbl_pin'
          axios.post(url, fd, config)
          .then(result => console.log(result.data))
          .then()
          .catch((error)=>Alerts('Error!', 'danger', error.message)) 
        } 
     const { SearchBar } = Search;
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, {text: '1000', value: 1000},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             
                             
                             </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    autoSelectText: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if(oldValue !==newValue){
                            handleUpdate(column, newValue, row.ID);
                            
                           return '';
                          }
                          
                        }
                                  }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }
  





 useEffect(()=>{
    fetchContent()
   },[]);

    return (<Template 
    name='Transaction PIN' 
    title='Customer Transaction PINs' 
    >

        
        {notice.isLoading?<Loader />:''}

        {errors.errorMessage?
<LabelWarning message ={errors.errorMessage} />:''}



                                
<div className="card" >
					<div className="card-header header-elements-inline">
						<h5 className="card-title">All PINS</h5>
						<div className="header-elements">
							<div className="list-icons">
		                		<a className="list-icons-item" data-action="collapse"></a>
		                		<a className="list-icons-item" data-action="reload"></a>
		                		<a className="list-icons-item" data-action="remove"></a>
		                	</div>
	                	</div>
					</div>

					<div className="card-body">
						

                       <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
            </div>
					</div>
				</div>

</Template>
);
       
}

export default React.memo(CustomersPin)  
