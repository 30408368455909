import React, {useState, useEffect} from 'react'

import { Token, config, ServerUrl, staffCode, PictureUrl } from './include';
import { Alerts } from './notify';
import axios from 'axios'
import Cookies from 'js-cookie'

const Topbar = ()=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
    
    
const [users, setUsers] = useState([])
const fetchContent =()=>{
    if(staffCode!=='null'){

    var sql ="Select passport, 	fullname from tbl_user where code ='"+staffCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller.php'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
            setUsers(result.data[0])
        }else{
            window.location.href='/' 
        }
        
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      
    })
    
    }else{
        window.location.href='/'
    }
}



useEffect(()=>{
   fetchContent() 
    },[]);


    return <div className="navbar navbar-expand-md navbar-dark fixed-top">
    <div className="navbar-brand">
        <a href="/dashboard" className="d-inline-block">
            <img src={PictureUrl+'/logo.png'} alt="" width="200" height="50"  />
        </a>
    </div>

    <div className="d-md-none">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
            <i className="icon-tree5"></i>
        </button>
        <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
            <i className="icon-paragraph-justify3"></i>
        </button>
    </div>

    <div className="collapse navbar-collapse" id="navbar-mobile">
        <ul className="navbar-nav">
            <li className="nav-item">
                <a href="#" className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
                    <i className="icon-paragraph-justify3"></i>
                </a>
            </li>

           
        </ul>

        <span className="badge bg-success ml-md-3 mr-md-auto">Online</span>

        <ul className="navbar-nav">
          

            <li className="nav-item dropdown">
                <a href="#" className="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
                    <i className="icon-bubbles4"></i>
                    <span className="d-md-none ml-2">Messages</span>
                    <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0">2</span>
                </a>
                
                <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350">
                    <div className="dropdown-content-header">
                        <span className="font-weight-semibold">Messages</span>
                        <a href="#" className="text-default"><i className="icon-compose"></i></a>
                    </div>

                    <div className="dropdown-content-body dropdown-scrollable">
                       
                    </div>

                    <div className="dropdown-content-footer justify-content-center p-0">
                        <a href="#" className="bg-light text-grey w-100 py-2" data-popup="tooltip" title="Load more"><i className="icon-menu7 d-block top-0"></i></a>
                    </div>
                </div>
            </li>

            <li className="nav-item dropdown dropdown-user">
                <a href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
                    <img src={PictureUrl +'/customers/'+users.passport} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/customers/Male.png"}} className="rounded-circle mr-2" height="34" alt="" />
                    <span>{users.fullname}</span>
                </a>

                <div className="dropdown-menu dropdown-menu-right">
                    <a href="#" className="dropdown-item"><i className="icon-user-plus"></i> My profile</a>
                    <a href="#" className="dropdown-item"><i className="icon-comment-discussion"></i> Messages <span className="badge badge-pill bg-blue ml-auto">2</span></a>
                    <div className="dropdown-divider"></div>
                    <a href="#" className="dropdown-item"><i className="icon-cog5"></i> Account settings</a>
                    <a href="/" className="dropdown-item"><i className="icon-switch2"></i> Logout</a>
                </div>
            </li>
        </ul>
    </div>
</div>
}
export default React.memo(Topbar)